<template>
  <v-card>
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>Info</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-chip
        v-if="data.isClient === '1' || data.isClient === 1"
        class="my-2"
        color="green"
        label
        text-color="white"
      >
        <v-icon left> mdi-trophy </v-icon>
        {{ $t("client") }}
      </v-chip>
      <br />

      <strong>Letzte Aktivität:</strong> {{ formatDate(data.lastLead) }}<br />
      <span v-if="data.email">
        <strong>E-Mail: </strong>
        <a :href="`mailto:${data.email}`">{{ data.email }}</a>
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "IdentityInfo",

  components: {},

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
    };
  },
  mixins: [helpers],
};
</script>
