<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="dark">
        <v-toolbar-title>Teilnahmen (Alias)</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>{{ $t("AliasActivitiesInfo") }}</p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("date") }}</th>
                <th class="text-left">{{ $t("Name") }}</th>
                <th class="text-left">{{ $t("phone") }}</th>
                <th class="text-left">{{ $t("campaign") }}</th>
                <th class="text-left">{{ $t("campaignType") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="alias in data" :key="alias.id">
                <td>{{ formatDate(alias.created) }}</td>
                <td>
                  <a class="clickable" @click="openDetails(alias)"
                    >{{ alias.firstName }} {{ alias.lastName }}</a
                  >
                </td>
                <td>{{ alias.phone }}</td>
                <td>{{ alias.campaigns_name }}</td>
                <td>
                  <v-chip small>{{ campaignType(alias) }}</v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Lead Detail</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <LeadDetailView
            v-if="cType === 'member'"
            ref="lead_detail"
            :leadId="leadId"
            :campaigns_id="campaign"
            :key="componentKey"
            type="member"
            @orderTerminated="orderTerminated()"
            @updated="$emit('updated')"
          ></LeadDetailView>

          <LeadDetailView
            v-else-if="cType === 'shop'"
            ref="lead_detail"
            :leadId="leadId"
            :campaigns_id="campaign"
            :key="componentKey"
            type="shop"
            @updated="$emit('updated')"
          ></LeadDetailView>

          <LeadDetail
            v-else
            ref="lead_detail"
            :id="leadId"
            :campaigns_id="campaign"
            :key="componentKey"
            @updated="$emit('updated')"
          ></LeadDetail>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import LeadDetail from "@/components/lead/manage/LeadDetail";
import LeadDetailView from "@/views/leads/LeadDetailView";
export default {
  name: "AliasActivities",

  components: {
    LeadDetail,
    LeadDetailView,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dialog: false,
      leadId: null,
      campaign: null,
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
      cType: "",
    };
  },

  methods: {
    campaignType(alias) {
      if (alias.hasMitfitMember === "1") {
        return "MEMBER";
      } else if (alias.hasMitfitShop === "1") {
        return "SHOP";
      } else if (alias.facebookLeadAds === "1") {
        return "FACEBOOK LEAD-AD";
      } else {
        return "STANDARD";
      }
    },

    openDetails(alias) {
      const id = alias.id;
      const campaign = alias.campaigns_id;

      if (alias.hasMitfitMember === "1") {
        this.cType = "member";
      } else if (alias.hasMitfitShop === "1") {
        this.cType = "shop";
      } else {
        this.cType = "standard";
      }

      this.componentKey++;
      this.leadId = id;
      this.campaign = campaign;
      this.dialog = true;
    },
  },

  mixins: [helpers],
};
</script>
