<template>
  <v-card>
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>Member</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <strong>Gesamtumsatz:</strong> {{ total }} EUR
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Datum</th>
              <th class="text-left">Produkt</th>
              <th class="text-left">Menge</th>
              <th class="text-left">Umsatz</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in data.items" :key="order.id">
              <td>{{ formatDate(order.created) }}</td>
              <td>{{ order.title }}</td>
              <td>{{ order.amount }}</td>
              <td>{{ order.priceGross }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "MemberActivities",

  components: {},

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
      total: 0,
    };
  },

  created: function () {
    this.calculateTotal();
  },

  methods: {
    async calculateTotal() {
      // rechne alle priceGross (convert it to number) values aus data.items zusammen, um den Gesamtumsatz zu berechnen. Runde auf 2 Dezimalstellen.
      this.total = this.data.items
        .reduce((acc, item) => acc + Number(item.priceGross), 0)
        .toFixed(2);
    },
  },

  mixins: [helpers],
};
</script>
