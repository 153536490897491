<template>
  <v-card>
    <v-toolbar dense dark color="dark">
      <v-toolbar-title>Mail-Channel</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>{{ $t("MailChannelActivitiesInfo") }}</p>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Channel</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="channel in data" :key="channel.id">
              <td>{{ channel.name }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "MailChannelActivities",

  components: {},

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
    };
  },
  mixins: [helpers],
};
</script>
