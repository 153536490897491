<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.leads')"
    />

    <DataTable
      :headers="headers"
      :items="data"
      :preloader="fetchinData"
      :edit="false"
      :watch="true"
      :trash="false"
      :copy="false"
      :leads="false"
      :preview="false"
      :stats="false"
      @tableEvent="emittedTableEvent"
    ></DataTable>

    <v-dialog
      v-model="dialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Personen Details</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <IdentityDetails
            :identityId="identityId"
            :key="componentKey"
            @updated="getData()"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import DataTable from "@/components/ui/DataTable";
import IdentityDetails from "@/components/identities/IdentityDetails";
export default {
  name: "CompanyIdentities",

  components: {
    PageHeader,
    DataTable,
    IdentityDetails,
  },

  data() {
    return {
      pageTitle: this.$t("Persons"),
      pageDescription: "",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      identityId: null,
      leadCampaign: null,
      leadData: [],
      data: [], // wrapper for fetched data
      headers: [],
      dialog: false,
      componentKey: 0,
    };
  },

  props: [],

  created: function () {
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/identities`
      );
      this.fetchinData = false;
      this.data = response.data.data;

      this.headers = [
        /* { text: 'id', value: 'id'}, */
        { text: this.$t("isClient"), value: "isClient" },
        { text: "Name(n)", value: "alias" },
        { text: this.$t("email"), value: "email" },
        { text: this.$t("lastActivity"), value: "lastLead" },
        { text: "", value: "actions", sortable: false, width: "30px" },
      ];
    },

    emittedTableEvent(obj) {
      this.identityId = obj.itemId;
      this.dialog = true;
      this.componentKey += 1;
    },
  },

  mixins: [manageApi],
};
</script>
