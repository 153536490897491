<template>
  <div class="mt-4" v-if="!fetchingData">
    <v-row>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <IdentityInfo :data="data" />
          </v-col>
          <v-col cols="12">
            <AliasActivities
              :data="data.alias"
              @updated="
                updateData();
                $emit('updated');
              "
            />
          </v-col>
          <v-col cols="12">
            <MailChannelActivities :data="data.mailChannel" />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <ShopActivities :data="data.shop" />
          </v-col>
          <v-col cols="12">
            <MemberActivities :data="data.member" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import AliasActivities from "@/components/identities/AliasActivities";
import ShopActivities from "@/components/identities/ShopActivities";
import MemberActivities from "@/components/identities/MemberActivities";
import MailChannelActivities from "@/components/identities/MailChannelActivities";
import IdentityInfo from "@/components/identities/IdentityInfo";
export default {
  name: "IdentityDetails",

  components: {
    AliasActivities,
    ShopActivities,
    MemberActivities,
    MailChannelActivities,
    IdentityInfo,
  },

  props: {
    identityId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fetchingData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      componentKey: 0,
      data: [],
    };
  },

  created: function () {
    this.getData();
  },

  mounted() {
    this.componentKey += 1;
  },

  methods: {
    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/identities/${this.identityId}`
      );
      this.fetchingData = false;
      this.data = response.data.data;
    },
    async updateData() {
      let response = await this.getRequest(
        `companies/${this.companyID}/identities/${this.identityId}`
      );
      this.data = response.data.data;
    },
  },
  mixins: [manageApi],
};
</script>
